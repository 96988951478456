<template>
  <li class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light">
    <a
      :href="authenticationHasRole('scope_customer') ? 'https://help.alturos.com/support/solutions ' : 'https://chillimetrix.alturos.com/confluence/display/SLSUPP/Metis+Help'"
      target="_blank"
      class="m-nav__link m-dropdown__toggle"
    >
      <span class="m-topbar__welcome d-table-cell verticalAlignMiddle">
        <font-awesome-icon
          v-tooltip="'Help?'"
          class="normalText"
          icon="question-circle"
        />
      </span>
    </a>
  </li>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
    name: "Help",
    mixins: [    
      authenticationMixin
    ]
}
</script>
